import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {ElLoading} from 'element-plus';

let loadingInstance: any;

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('@/components/layout/DefaultLayout.vue'),
        children: [
            {
                path: '/',
                name: 'index',
                component: () => import('@/views/IndexView.vue'),
            },
            {
                path: '/modalPageThree',
                name: 'modalPageThree',
                component: () => import('@/views/modalPageThree.vue'),
            },
            {
                path: '/newsDetail',
                name: 'newsDetail',
                component: () => import('@/views/NewsDetail.vue'),
            },
            {
                path: '/contact',
                name: 'contact',
                component: () => import('@/views/ContactUs.vue'),
            },
            {
                path: '/modalPageOne',
                name: 'modalPageOne',
                component: () => import('@/views/modalPageOne.vue'),
            },
            {
                path: '/modalPageTwo',
                name: 'modalPageTwo',
                component: () => import('@/views/modalPageTwo.vue'),
            },
            {
                path: '/modalPageZero',
                name: 'modalPageZero',
                component: () => import('@/views/modalPageZero.vue'),
            },
            {
                path: '/modalPageFour',
                name: 'modalPageFour',
                component: () => import('@/views/modalPageFour.vue'),
            },
            {
                path: '/about',
                name: 'about',
                component: () => import('@/views/aboutUs.vue'),
            },
            {
                path: '/career',
                name: 'career',
                component: () => import('@/views/career.vue'),
            }
        ]
    },
    {
        path: "/:catchAll(.*)",
        redirect: '/'
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (loadingInstance && typeof loadingInstance.close === "function") {
        loadingInstance.close();
        loadingInstance = null;
    }
    loadingInstance = ElLoading.service({
        fullscreen: true
    });
    next();
})

router.afterEach((to, from) => {
    if (loadingInstance && loadingInstance.hasOwnProperty('close') && typeof loadingInstance.close === "function") {
        loadingInstance.close();
        loadingInstance = null;
    }
})
export default router
